<template>
    <div>
        <div class="top">
            <div class="title">意见反馈</div>
        </div>
        <div class="form">
            <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
            >
                <el-form-item label="反馈内容" prop="Detail">
                    <el-input
                        type="textarea"
                        :rows="6"
                        maxlength="200"
                        show-word-limit
                        style="width:500px"
                        placeholder="请输入您的宝贵意见和建议，我们将会做的更好~"
                        v-model="ruleForm.Detail"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="图片" prop="Detail">
                    <el-upload
                        :action="url"
                        list-type="picture-card"
                        :data="{
                            directory: 'Feedback'
                        }"
                        :on-exceed="handleExceed"
                        :limit="5"
                        :file-list="fileList"
                        :on-success="handleSuccess"
                        :on-remove="handleRemove"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <div class="botton" @click="submitForm('ruleForm')">
                        <el-button class="botton">提交</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { creatFeedBack } from './service'
export default {
    data() {
        return {
            url: '',
            ruleForm: {},
            fileList: [],
            rules: {}
        }
    },
    mounted() {
        this.url = FILE_URL + '/UploadFile/UploadImg'
    },
    methods: {
        handleSuccess(response, file, fileList) {
            this.fileList = fileList
        },
        handleRemove(file, fileList) {
            this.fileList = fileList
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleExceed() {
            this.$message.warning(`当前限制选择最多上传5个文件`)
        },
        submitForm() {
            let Pictrue = ''
            for (const key in this.fileList) {
                Pictrue += this.fileList[key].response.data[0].img + ','
            }
            Pictrue = Pictrue.substr(0, Pictrue.length - 1)
            let data = {
                Pictrue,
                Detail: this.ruleForm.Detail
            }
            creatFeedBack(data).then(() => {
                this.$message.success('反馈成功')
                setTimeout(() => {
                    location.reload()
                }, 1000)
            })
        }
    }
}
</script>

<style lang="less" scoped>
.botton {
    width: 100px;
    background: #0097ba;
    border: 1px solid #0097ba;
    color: #ffffff;
}
.form {
    margin-top: 40px;
}
.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }

    .policy {
        font-size: 12px;
        color: #0097ba;
        margin-top: 10px;
    }
}
</style>
