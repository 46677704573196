import { post } from '@/http'

// 提交意见反馈
export function creatFeedBack(data) {
    return new Promise((resolve, reject) => {
        post('/FeedBack/CreatFeedBack', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
